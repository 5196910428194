import React from 'react';
import styled, { css } from 'styled-components';
import { getSrcSet } from 'gatsby-plugin-image';

import Link from '@core/Link';
import isSmall from '@utils/isSmall';
import { appear, unflicker } from '@mixins';
import mq, { mqdown } from '@mq';

const FullpageSection = ({ to, images }) => (
  <Container className="fullpage-section-project" as={isSmall && Link} to={to}>
    {images.map((image, index) => (
      <Image key={index} image={image} />
    ))}
  </Container>
);

const Image = ({ image }) => (
  <ImageContainer className="fullpage-slide-image">
    <div>
      <ParallaxImage className="fp-bg" backgroundImageSet={getImageMediaQueries(image)} />
      <div />
    </div>
  </ImageContainer>
);

const Container = styled.div`
  ${unflicker};
  cursor: none;
`;

const ParallaxImage = styled.div`
  z-index: 3;
  background-size: cover;
  background-position: center;

  ${mqdown.small} {
    --motion-delay: 400ms;
    ${appear};
  }

  ${props => css`
    ${props.backgroundImageSet.default};

    ${mq.small} {
      ${props.backgroundImageSet.small};
    }

    ${mq.medium} {
      ${props.backgroundImageSet.medium};
    }
  `};
`;

const ImageContainer = styled.div`
  ${mqdown.small} {
    display: none;

    &:first-child {
      display: block;
    }
  }
`;

function getImageMediaQueries(images) {
  const srcSet = getSrcSet(images)
    .split('\n')
    .map(size => ({
      src: size.split(' ')[0],
      mq: parseInt(size.split(' ')[1].replace('w', ''), 10),
    }));

  return {
    default: `background-image: url(${srcSet.find(item => item.mq === 1366).src});`,
    small: `background-image: url(${srcSet.find(item => item.mq === 1920).src});`,
    medium: `background-image: url(${srcSet[srcSet.length - 1].src});`,
  };
}

function getFurthestExistingItem(array, index) {
  if (array[index + 2]) {
    return array[index + 2];
  }
  if (array[index + 1]) {
    return array[index + 1];
  }
  return array[index];
}

export default FullpageSection;
