import { mqdown } from '@mq';
import isSmall from '@utils/isSmall';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const FullpageCursor = ({ cursorText, containerRef }) => {
  const [direction, setDirection] = useState(null);

  const handleMouseMove = useCallback(event => {
    if (event.clientX / window.innerWidth < 0.5) {
      setDirection('previous');
    } else {
      setDirection('next');
    }

    document.documentElement.style.setProperty('--cursor-x', event.clientX / window.innerWidth);
    document.documentElement.style.setProperty('--cursor-y', event.clientY / window.innerHeight);
  }, []);

  const handleMouseClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      if (!window.fullpage_api) {
        return;
      }

      if (direction === 'previous') {
        window.fullpage_api.moveSlideLeft();
      } else {
        window.fullpage_api.moveSlideRight();
      }
    },
    [direction]
  );
  useEffect(() => {
    const container = containerRef.current;

    if (isSmall) {
      return undefined;
    }

    window.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('click', handleMouseClick);
    container.addEventListener('mouseenter', showCursor);
    container.addEventListener('mouseleave', hideCursor);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('click', handleMouseClick);
      container.removeEventListener('mouseenter', showCursor);
      container.removeEventListener('mouseleave', hideCursor);
    };
  }, [handleMouseMove, handleMouseClick, containerRef]);

  return (
    <Container>
      <div>
        {direction === 'previous' && `← `}
        {`${zeroPad(cursorText.index)}/${zeroPad(cursorText.length)}`}
        {direction === 'next' && ` →`}
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 4;
  user-select: none;
  pointer-events: none;
  cursor: none;

  transform: translate3d(
    calc(100vw * var(--cursor-x, -1) - 50%),
    calc(100vh * var(--cursor-y, -1) - 10px),
    0
  );

  opacity: var(--cursor-opacity, 0);

  > div {
    cursor: none;
    pointer-events: none;
  }

  ${mqdown.medium} {
    display: none;
  }
`;

function zeroPad(num) {
  return String(num).padStart(2, '0');
}

function hideCursor() {
  document.documentElement.style.setProperty('--cursor-opacity', 0);
}

function showCursor() {
  document.documentElement.style.setProperty('--cursor-opacity', 1);
}

export default FullpageCursor;
