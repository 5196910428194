import Link from '@core/Link';
import RichText from '@core/RichText';
import { cover, grid, layoutWrapper, truncate } from '@mixins';
import mq, { hover, mqdown } from '@mq';
import isSmall from '@utils/isSmall';
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import useOutsideClick from '@hooks/useOutsideClick';

const ProjectInfo = ({ title, subtitle, author, details, quote, description, to }) => {
  const [visible, setVisible] = useState(false);

  const show = useCallback(() => {
    if (isSmall) {
      return;
    }

    setVisible(true);
    hideNavigation();
  }, []);

  const hide = useCallback(() => {
    if (isSmall) {
      return;
    }

    setVisible(false);
    showNavigation();
  }, []);

  const toggle = useCallback(() => {
    if (visible) {
      hide();
    } else {
      show();
    }
  }, [visible, hide, show]);

  const ref = useOutsideClick(hide);

  return (
    <div
      {...(!isSmall && {
        ref,
      })}
    >
      <Container onMouseEnter={show} onMouseLeave={hide} visible={visible}>
        <ContainerInner>
          <Header as={!isSmall && 'div'} to={isSmall ? to : null} onClick={toggle}>
            <div>{title}</div>
          </Header>
          {subtitle && <SecondaryTitle>{subtitle}</SecondaryTitle>}
          {author && <SecondaryTitle>{author}</SecondaryTitle>}
          <Body>
            <Details text={details} />
            <Quote text={quote} />
            <Description text={description} />
          </Body>
        </ContainerInner>
      </Container>
      <Plus onClick={toggle}>+</Plus>
    </div>
  );
};

const Body = styled.div`
  ${grid};
  padding-bottom: var(--gutter);
  row-gap: 1rem;
  opacity: 0;
  transition: opacity var(--motion);
`;

const Header = styled(Link)`
  ${layoutWrapper};
  display: flex;
  justify-content: space-between;
  padding-top: var(--navigation-padding-block);
  padding-bottom: var(--navigation-padding-block);

  ${mqdown.small} {
    justify-content: center;
    text-align: center;
    ${truncate};
  }
`;

const SecondaryTitle = styled.div`
  ${layoutWrapper};
  margin-top: calc(-1 * var(--navigation-padding-block));
  padding-bottom: var(--navigation-padding-block);
  opacity: 0;
  transition: opacity var(--motion);
`;

const Plus = styled.div`
  opacity: 1;
  transition: opacity var(--motion);
  font-size: 1.5rem;
  transition: transform var(--motion-quadruple);

  position: fixed;
  bottom: calc(0.5 * var(--navigation-padding-block));
  right: var(--gutter);
  z-index: 102;

  padding: 1rem;
  margin: -1rem;

  ${mqdown.small} {
    display: none;
  }
`;

const ContainerInner = styled.div`
  /* ${cover}; */
  transition: transform var(--motion-quadruple),
    background-color var(--motion-quadruple) var(--motion);
`;

const visibleStyles = css`
  transform: translate3d(0, var(--navigation-height), 0);
  transition: transform var(--motion-quadruple), background-color 0ms;

  ${ContainerInner} {
    background-color: var(--color-background);
    transition: transform var(--motion-quadruple), background-color 0ms;
  }

  ${Body} {
    opacity: 1;
    transition: opacity var(--motion-quadruple);
  }

  ${SecondaryTitle} {
    opacity: 1;
    transition: opacity var(--motion-quadruple);
  }

  & + ${Plus} {
    ${mq.small} {
      transform: rotate(45deg);
    }

    ${hover} {
      display: none;
    }
  }
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  bottom: var(--navigation-height);
  z-index: 101;
  transform: translate3d(0, calc(100% - var(--navigation-height)), 0);
  min-height: calc(50vh + var(--navigation-height));
  transition: transform var(--motion-quadruple),
    background-color var(--motion-quadruple) var(--motion);
  font-feature-settings: normal;
  padding-top: var(--navigation-height);
  display: flex;

  ${hover} {
    &:hover {
      ${visibleStyles};
    }
  }

  ${props => props.visible && visibleStyles}
`;

const Details = styled(RichText)`
  grid-column: 1 / -1;

  ${mq.medium} {
    grid-column: 1 / 4;
  }
`;

const Quote = styled(RichText)`
  grid-column: 1 / -1;
  link-style: italic;

  ${mq.medium} {
    grid-column: 4 / 7;
  }

  blockquote {
    font-style: italic;
    font-feature-settings: 'ss01';
    margin-bottom: 1em;
  }
`;

const Description = styled(RichText)`
  grid-column: 1 / -1;

  ${mq.medium} {
    grid-column: 7 / -1;
  }
`;

function hideNavigation() {
  document.body.classList.add('fullpage-navigation-hidden');
}

function showNavigation() {
  document.body.classList.remove('fullpage-navigation-hidden');
}

export default ProjectInfo;
