import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import Fullpage from '@components/Fullpage';

const HomePage = ({
  data: {
    contentfulHomepage: { projects },
  },
}) => {
  const formattedProjects = useMemo(
    // joins coverImage and images into single images array
    () =>
      projects.map(project => ({ ...project, images: [project.coverImage, ...project.images] })),
    [projects]
  );

  return <Fullpage sections={formattedProjects} />;
};

export const homepageQuery = graphql`
  query HomepageQuery {
    contentfulHomepage {
      projects {
        title
        slugifiedTitle
        author
        subtitle
        to
        details {
          raw
        }
        quote {
          raw
        }
        description {
          raw
        }
        coverImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 75
          )
        }
        images {
          gatsbyImageData(
            layout: FULL_WIDTH
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            quality: 75
          )
        }
      }
    }
  }
`;

export default HomePage;
